import React, {useEffect, useState} from "react";
import {
  Grid,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
} from "@mui/material";
import {useNavigate} from "react-router-dom";
import moment from "moment";

import Container from "../../components/Container";
import Page from "../../components/Page";
import AlertMsg from "../../components/AlertMsg";

import {UTC_OFFSET} from '../../constants'

import {connect} from "react-redux";
import {
  listLog,
} from "../../redux/actions/userDataActions";

import styles from "./Logs.module.css";

const Logs = (props) => {
  const [pageNum, setPageNum] = useState(1);
  const [totalListCount, setTotalListCount] = useState("");
  const [listCount, setListCount] = useState("");
  const [pageLimit, setPageLimit] = useState(50);

  const navigate = useNavigate();

  useEffect(() => {
    setTotalListCount(props.logTotalList);
  }, [props.logTotalList]);

  useEffect(() => {
    setListCount(props.logList?.length);
  }, [props.logList?.length]);


  useEffect(() => {
    getLogsList(1);
  }, []);

  const getLogsList = (page) => {
    let data = {
      page: page,
    };
    props.listLog(JSON.stringify(data));
    setPageNum(page);
  };

  const onSelectPage = (page) => {
    getLogsList(page);
  };


  return (
    <Container page={"logs"} title={"Logs"}>
      <Grid container item md={12} xs={12}>
        <Grid
          item
          md={12}
          xs={12}
          container
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          className={styles.cardView}
        >
          <TableContainer className={styles.tableView}>
            <Table aria-label="simple table">

              <TableHead>
                <TableRow>
                  <TableCell>Email</TableCell>
                  <TableCell>Location</TableCell>
                  <TableCell>Available Slots</TableCell>
                  <TableCell>Last Booked Date</TableCell>
                  <TableCell>Booked Date</TableCell>
                  <TableCell>Booked Time</TableCell>
                  <TableCell>Timestamp</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.logLoading ? (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      <CircularProgress/>
                    </TableCell>
                  </TableRow>
                ) : props.logList?.length > 0 ? (
                  props.logList?.map((item, index) => (
                    <TableRow
                      key={index}
                    >
                      <TableCell>{item?.email ? item?.email : '-'}</TableCell>
                      <TableCell>{item?.location ? item?.location : '-'}</TableCell>
                      <TableCell>
                        {item?.available_slots?.length > 0 ?
                          item?.available_slots?.map((x, i) => {
                            return (
                              <Box key={i} pb={1}>
                                <Typography className={styles.slotDateText}>{x?.date}</Typography>
                              </Box>
                            )
                          })
                          : '-'}
                      </TableCell>
                      <TableCell className={styles.dataView}>{item?.last_booked_date ? moment(item.last_booked_date).format("DD-MM-YYYY") : '-'}</TableCell>
                      <TableCell className={styles.dataView}>{item?.booked_date ? moment(item.booked_date).format("DD-MM-YYYY") : '-'}</TableCell>
                      <TableCell>{item?.booked_time ? item.booked_time : '-'}</TableCell>
                      <TableCell>{moment.utc(item?.timestamp).utcOffset(UTC_OFFSET).format("DD-MM-Y hh:mm A")}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      <Typography className={styles.noMatchFoundText}>
                        No Logs found
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <Grid
            item
            md={12}
            xs={12}
            display={"flex"}
            flexDirection={{md: "row", xs: "column"}}
            alignItems={"center"}
          >
            <Grid
              item
              md={6}
              xs={12}
              display={"flex"}
              justifyContent={{md: "flex-start", xs: "unset"}}
              mt={2}
              mb={{md: 2, xs: 0}}
              pl={{md: 2, xs: 0}}
            >
              <Typography className={styles.showingEntriesText}>
                Showing{" "}
                {listCount == 0
                  ? 0
                  : pageNum == 1
                    ? 1
                    : parseInt((pageNum - 1) * pageLimit) + 1}{" "}
                to{" "}
                {listCount == 0
                  ? 0
                  : parseInt((pageNum - 1) * pageLimit) + listCount}{" "}
                of {totalListCount} entries
              </Typography>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
              display={"flex"}
              justifyContent={{md: "flex-end", xs: "unset"}}
              mt={2}
              mb={2}
              pr={{md: 2, xs: 0}}
            >
              <Page
                totalPages={props.logTotalPages}
                pageNum={pageNum}
                onSelectPage={onSelectPage}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <AlertMsg/>
    </Container>
  )
};

const mapStateToProps = (state) => {
  return {
    logTotalPages: state.userData.logTotalPages,
    logTotalList: state.userData.logTotalList,
    logList: state.userData.logList,
    logLoading: state.userData.logLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    listLog: (data) => dispatch(listLog(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Logs);

